<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <v-card elevation="0">
            <v-card-actions>
                <v-card-title>{{ formTitle }}</v-card-title>
                <v-spacer></v-spacer>
                <v-btn :to="{ name: 'ClientSettings', query: { tab: 'cf'} }" color="green" rounded
                >
                {{$t('Cancel')}}
                </v-btn>
                <v-btn
                :disabled="!valid"
                color="primary"
                @click="save"
                rounded
                >
                {{$t('Save')}}
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-text-field
                            v-model="item.name"
                            :label="$t('Name')"
                            :rules="rules.name"
                            required
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="types"
                        item-text="name"
                        item-value="value"
                        v-model="item.type"
                        :label="$t('Type')"
                        :rules="rules.type"
                        required
                        >
                            <template v-slot:item="{item}">
                                {{$t(item.name)}}
                            </template>
                        </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
export default {

    data() {
        return {
            valid: true,
            item: {},
            id:"",
            loading:false,
            rules: {
                name:[
                    value => !!value || this.$t('Required.'),
                    value => (value || '').length <= 100 || this.$t('Max 100 characters'),
                ],
                type:[
                    value => !!value || value == 0 || this.$t('Type is Required'),
                ],
            },
            types:[],
        }
    },

    computed: {
        formTitle () {
            return this.id == "" ? this.$t('Add Relation Custom Field') : this.$t('Edit Relation Custom Field')
        },
    },

    created: function()
    {
        if(this.$route.query.id){
            this.id = this.$route.query.id
            this.getItem();
        }
        this.fetchTypes();
    },

    methods: {
        fetchTypes()
        {
            let uri = this.$urlPrefix + '/CustomFieldType/GetAll';
            this.axios.get(uri).then((response) => {
                this.types = response.data;
            });
        },
        getItem()
        {
            this.loading = true
            let uri = this.$urlPrefix + '/RelationCustomField/Get?id=' + this.id;
            this.axios.get(uri).then((response) => {
                this.item = response.data;
                this.loading = false
            });
        },
        save () {
            if(this.$refs.form.validate()){
                this.loading = true
                if (this.id != "") {
                    let uri = this.$urlPrefix + '/RelationCustomField/Update/';
                    this.axios.put(uri, this.item).then((response) => {
                        if(response.status == 204 || response.status == 200){
                                this.loading = false
                                this.$router.push({name: 'ClientSettings', query: { tab: 'cf'}});
                        }
                    });
                } else {
                    let uri = this.$urlPrefix + '/RelationCustomField/Add';
                    this.axios.post(uri, this.item).then((response) => {
                        if(response.status == 200){
                            this.item = {}
                            this.loading = false
                            this.$router.push({name: 'ClientSettings', query: { tab: 'cf'}});
                        }
                    });
                }
            }
        },
    }
}
</script>
